// import numeral from "numeral";
// import React from "react";
// import { formatMonth } from "../../../utils/utils";

// const LabourAnalysis = ({
//   LabourInsightsHeader,
//   TotalActualLabourRev,
//   ActualLabourRevArr,
//   ActualLabourRev,
//   TotalActualLabourCost,
//   ActualLabourCostArr,
//   TotalGrossPAL,
//   GrossPAL,
//   TotalPerMargin,
//   PercentMargin,
//   Time_Sheet,
//   TotalForecastedLabourRevenue,
//   ForecastedLabourRevenue,
//   TotalLabourRevenueVariance,
//   LabourRevenueVariance,
//   TotalLabourRevenueVariancePer,
//   LabourRevenueVariancePer,
//   TotalActualLabourHours,
//   ActualLabourHours,
//   TotalLabourHoursTarget,
//   LabourHoursTarget,
//   TotalLabourHourLG,
//   LabourHourLG,
// }) => {
//   return (
//     <div className="LabourAnalysis">
//       <div className="TableNew">
//         <div className="TableFieldNames">
//           <div className="Item"></div>
//           <div className="Item">Actual Labour Revenue (% INV)</div>
//           <div className="Item">Actual Labour Cost</div>

//           <div className="Item">Gross Profit Attributable to Labour</div>
//           <div className="Item">% Margin</div>
//           <div className="Item divider"></div>

//           <div className="Item">Forecasted Labour Revenue</div>
//           <div className="Item">Labour Revenue Variance</div>
//           <div className="Item">Labour Revenue Variance (%)</div>

//           <div className="Item divider"></div>

//           <div className="Item">Actual Labour Hours</div>
//           <div className="Item">Labour Hours Target</div>
//           <div className="Item">Labour Hour Loss / Gain</div>
//           <div className="Item divider"></div>
//         </div>
//         <div className="TableFieldData">
//           <div className="DataTable Heading">
//             <div className="Item Total">Total</div>

//             {LabourInsightsHeader.map((x, i) => (
//               <>
//                 <div className="">-</div>
//                 <div className="Item">
//                   <div className="First">{formatMonth(x)}</div>
//                   <div className="Second">Month {i + 1}</div>
//                 </div>
//               </>
//             ))}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalActualLabourRev).format("0,0")}
//             </div>

//             {ActualLabourRevArr.length > 0
//               ? ActualLabourRevArr.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalActualLabourCost).format("0,0")}
//             </div>
//             {ActualLabourCostArr.length > 0
//               ? ActualLabourCostArr.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalGrossPAL).format("0,0")}
//             </div>
//             {GrossPAL.length > 0
//               ? GrossPAL.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalPerMargin.toFixed(0)}%
//             </div>
//             {PercentMargin.length > 0
//               ? PercentMargin.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}%</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="dividertable"></div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {numeral(TotalForecastedLabourRevenue).format("0,0")}
//             </div>
//             {ForecastedLabourRevenue.length > 0
//               ? ForecastedLabourRevenue.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {numeral(TotalLabourRevenueVariance).format("0,0")}
//             </div>
//             {LabourRevenueVariance.length > 0
//               ? LabourRevenueVariance.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourRevenueVariancePer.toFixed(0)}%
//             </div>
//             {LabourRevenueVariancePer.length > 0
//               ? LabourRevenueVariancePer.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}%</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="dividertable"></div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalActualLabourHours.toFixed(0)}H
//             </div>
//             {ActualLabourHours.length > 0
//               ? ActualLabourHours.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourHoursTarget.toFixed(0)}H
//             </div>
//             {LabourHoursTarget.length > 0
//               ? LabourHoursTarget.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourHourLG.toFixed(0)}H
//             </div>
//             {LabourHourLG.length > 0
//               ? LabourHourLG.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//         </div>
//       </div>
//     </div>
//   );
// };

// export default LabourAnalysis;

import numeral from "numeral";
import { formatMonth } from "../../../utils/utils";
import React, { useState, useRef, useEffect } from "react";
import detailsfilter from "../../../assets/images/details filter logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { SelectTimeFilter } from "../../../reducers/AllApis";
import arrow from "../../../assets/images/table arrow/vuesax/linear/vuesax/linear/Vector.svg";

const LabourAnalysis = ({
  LabourInsightsHeader = [],
  TotalActualLabourRev = 0,
  ActualLabourRevArr = [],
  TotalActualLabourCost = 0,
  ActualLabourCostArr = [],
  TotalGrossPAL = 0,
  GrossPAL = [],
  TotalPerMargin = 0,
  PercentMargin = [],
  Time_Sheet = [],
  TotalForecastedLabourRevenue = 0,
  ForecastedLabourRevenue = [],
  TotalLabourRevenueVariance = 0,
  LabourRevenueVariance = [],
  TotalLabourRevenueVariancePer = 0,
  LabourRevenueVariancePer = [],
  TotalActualLabourHours = 0,
  ActualLabourHours = [],
  TotalLabourHoursTarget = 0,
  LabourHoursTarget = [],
  TotalLabourHourLG = 0,
  LabourHourLG = [],
  BudgetedLabourCostArr = [],
  TotalBudgetedLabourCost = 0,
  TotalEarnedValueArr = 0,
  EarnedValueArr = [],
}) => {
  const maxColumns = Math.max(
    LabourInsightsHeader.length,
    ActualLabourRevArr.length,
    ActualLabourCostArr.length,
    GrossPAL.length,
    PercentMargin.length,
    ForecastedLabourRevenue.length,
    LabourRevenueVariance.length,
    LabourRevenueVariancePer.length,
    ActualLabourHours.length,
    LabourHoursTarget.length,
    LabourHourLG.length,
    BudgetedLabourCostArr.length,
    EarnedValueArr.length
  );

  const renderRow = (
    dataArr = [],
    totalValue = "0",
    formatFn = null,
    isPercentage = false,
    suffix = "",
    isCurrency = true
  ) => (
    <div className="DataTable">
      <div className="Item Total">{totalValue}</div>
      {Array.from({ length: maxColumns }).map((_, i) => (
        <React.Fragment key={i}>
          <div className=""></div>
          <div className="Item">
            {dataArr[i] !== undefined &&
            dataArr[i] !== null &&
            !isNaN(Number(dataArr[i]))
              ? formatFn
                ? `${formatFn(Number(dataArr[i]))}`
                : isPercentage
                ? `${Number(dataArr[i]).toFixed(0)}`
                : isCurrency
                ? numeral(Number(dataArr[i])).format("0,0")
                : `${Number(dataArr[i]).toFixed(0)}`
              : isPercentage
              ? "0"
              : isCurrency
              ? "0"
              : `0`}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const dispatch = useDispatch();

  const detailspopupRef = useRef(null);
  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const detailstogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      detailspopupRef.current &&
      !detailspopupRef.current.contains(event.target)
    ) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { SelectedTime } = useSelector((state) => state.allapi);
  const handleSelectTime = (data) => {
    dispatch(SelectTimeFilter(data));
  };

  return (
    <div className="LabourAnalysis labourcard ">
      {Time_Sheet.length > 0 ? (
        <div className="TableNew">
          <div className="TableFieldNames">
            <div className="Item" style={{ marginBottom: "20px" }}>
              <div className="detailsfilter detailstablecard" ref={inputRef}>
                <div
                  className="flex detailstablefilter"
                  onClick={detailstogglePopup}
                  style={{ marginRight: "20px" }}
                >
                  <p>{SelectedTime === "monthly" ?  "This Month" : SelectedTime === "yearly" ? "This Yearly" : SelectedTime === "quarterly" ? "Quarterly" : "View by"}</p>
              
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5427 8.95001L14.0227 15.47C13.2527 16.24 11.9927 16.24 11.2227 15.47L4.70267 8.95001"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {/* <img src={arrow}/> */}
                </div>

                {isPopupOpen && (
                  <div ref={detailspopupRef} className="graphpopup">
                    <div className="popup-content">
                      <p onClick={(e) => handleSelectTime("monthly")}>
                        Monthly
                      </p>
                      <p onClick={(e) => handleSelectTime("yearly")}>
                        Yearly
                      </p>
                      <p onClick={(e) => handleSelectTime("quarterly")}>
                        Quarterly
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Item">Actual Labour Revenue ($)</div>
            <div className="Item subitem">Actual Labour Cost ($)</div>
            <div className="Item subitem">Budgeted Labour Cost ($)</div>
            <div className="Item subitem">Earned Value ($)</div>
            <div className="Item subitem">Gross Profit Attributable to Labour ($)</div>
            <div className="Item subitem">% Margin</div>
            <div className="Item divider"></div>
            <div className="Item">Forecasted Labour Revenue ($)</div>
            <div className="Item subitem">Labour Revenue Variance ($)</div>
            <div className="Item subitem">Labour Revenue Variance (%)</div>
            <div className="Item divider"></div>
            <div className="Item">Actual Labour Hours (H)</div>
            <div className="Item subitem">Labour Hours Target (H)</div>
            <div className="Item subitem">Labour Hour Loss / Gain (H)</div>
            <div className="Item divider"></div>
          </div>
          <div className="TableFieldData">
            <div className="DataTable Heading">
              <div className="Item Total">Total</div>
              {Array.from({ length: maxColumns }).map((_, i) => (
                <React.Fragment key={i}>
                  <div className=""></div>
                  <div className="Item">
                    {LabourInsightsHeader[i] ? (
                      <>
                        <div className="First">
                          {formatMonth(LabourInsightsHeader[i])}
                        </div>
                        <div className="Second">
                          {SelectedTime === "monthly"
                            ? "Month"
                            : SelectedTime === "yearly"
                            ? "Year"
                            : SelectedTime === "quarterly"
                            ? "Quarter"
                            : ""}{" "}
                          {i + 1}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            {renderRow(
              ActualLabourRevArr,
              numeral(TotalActualLabourRev).format("0,0")
            )}
            {renderRow(
              ActualLabourCostArr,
              numeral((TotalActualLabourCost)).format("0,0")
            )}
            {renderRow(
              BudgetedLabourCostArr,
              numeral(TotalBudgetedLabourCost).format("0,0")
            )}
            {renderRow(
              EarnedValueArr,
              numeral(TotalEarnedValueArr).format("0,0")
            )}
            {renderRow(GrossPAL, numeral(TotalGrossPAL).format("0,0"))}
            {renderRow(
              PercentMargin,
              `${TotalPerMargin ? TotalPerMargin.toFixed(0) : "0"}`,
              null,
              true
            )}
            <div className="dividertable"></div>
            {renderRow(
              ForecastedLabourRevenue,
              numeral(TotalForecastedLabourRevenue).format("0,0")
            )}
            {renderRow(
              LabourRevenueVariance,
              numeral(TotalLabourRevenueVariance).format("0,0")
            )}
            {renderRow(
              LabourRevenueVariancePer,
              `${
                TotalLabourRevenueVariancePer
                  ? TotalLabourRevenueVariancePer.toFixed(0)
                  : "0"
              }`,
              null,
              true
            )}
            <div className="dividertable"></div>
            {renderRow(
              ActualLabourHours,
              `${
                TotalActualLabourHours
                  ? TotalActualLabourHours.toFixed(0)
                  : "0"
              }H`,
              null,
              false,
              "H",
              false
            )}

            {renderRow(
              LabourHoursTarget,
              `${
                TotalLabourHoursTarget
                  ? TotalLabourHoursTarget.toFixed(0)
                  : "0"
              }H`,
              null,
              false,
              "H",
              false
            )}

            {renderRow(
              LabourHourLG,
              TotalLabourHourLG
                ? numeral(TotalLabourHourLG).format("0,0")
                : "$0"
            )}
          </div>
        </div>
      ) : (
        <div className="ItemHead">No Data</div>
      )}
    </div>
  );
};

export default LabourAnalysis;
