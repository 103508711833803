import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../reducers/AllApis";
import UserOne from "../assets/images/user-icon.svg";
import moonlight from "../assets/images/moon-light.svg";
import sunlight from "../assets/images/sun icon.png";
import { NavLink, useLocation } from "react-router-dom";
import { logout } from "../reducers/Auth";

const Header = (props) => {
  const [SearchTerm, setSearchTerm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popupRef.current &&
      !popupRef.current.contains(event.target)
    ) {
      setSearchTerm("");
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const { theme } = useSelector((state) => state.allapi);
  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const ProfileData = localStorage.getItem("ProfileData");
  const ProfileDataMain = ProfileData ? JSON.parse(ProfileData) : [];

  const DateForm = (datenew) => {
    if (!datenew) {
      return "";
    }

    const date = new Date(datenew);
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };


  const JobDetailsData = JSON.parse(sessionStorage.getItem("JobDetail"))


  return (
    <header
      className="justify-between HeaderMain sticky top-0  flex w-full"
      style={{ minHeight: "50px" }}
    >
      <div className="flex justify-between sticky top-0  flex w-full">
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4">
          <svg
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            style={{ fill: "currentColor" }}
            className="c-pointer z-99999 block rounded-sm border bg-white p-1.5 shadow-sm dark:bg-boxdark sidebarbtn"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="20px"
            height="20px"
          >
            <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
          </svg>
        </div>
      </div>
      <div className="flex headeralign">
      
      {
        JobDetailsData !== null ? <div className="deskdetailsheader" style={{display:"flex",alignItems:"center"}}>
         <div className="JobDetailsHeader">
        <div className="Sec">
          Job #J{JobDetailsData.job_id}
        </div>
        <div className="Sec">
          Site: {JobDetailsData.site_name}
        </div>
        <div className="Sec">
        Client: {JobDetailsData.client_firm}
        </div>
        <div className="Sec">
        Project Manager: {JobDetailsData.manager}
        </div>
      </div> 
      </div>: <div className="JobDetailsHeaderTitle">{props.Heading}</div>
      }
     
      <div className="UtilsMain">
        {/* <div>
          <div className="hidden text-right lg:block profiledtata">
            <div className="block text-sm font-medium text-black dark:text-white">
              {ProfileDataMain.first_name || "-"}{" "}
              {ProfileDataMain.last_name || "-"} (Last Login:{" "}
              {ProfileDataMain.last_login
                ? DateForm(ProfileDataMain.last_login)
                : "-"}
              )
            </div>
            <div className="block text-xs">{ProfileDataMain.email || "-"}</div>
          </div>
        </div> */}
        <div className="Profile headericons" style={{ position: "relative" }}>
          <img
            // src={UserOne}
             src={ProfileDataMain?.profile ? ProfileDataMain?.profile : UserOne}
            onClick={togglePopup}
            alt="User Icon"
            style={{ cursor: "pointer" }}
            ref={inputRef}
          />
          {isPopupOpen && (
            <div ref={popupRef} className="HeaderPopup">
              <div className="block text-sm font-medium text-black dark:text-white">
                <div className="Name ">
                  {ProfileDataMain.first_name || "-"} {ProfileDataMain.last_name || "-"}
                  
                </div>
                <div className="Email">
                  (Last Login:
                  {ProfileDataMain.last_login
                    ? DateForm(ProfileDataMain.last_login)
                    : "-"}
                  )
                </div>
                <div className="Email">{ProfileDataMain.email || "-"}</div>
              </div>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <NavLink
                    to="/settings"
                    style={{ gap: "10px", padding: "8px 0px", display: "flex" }}
                  >
                    <svg
                      viewBox="0,0,256,256"
                      width="20px"
                      height="20px"
                      fill-rule="nonzero"
                    >
                      <g
                        fill="currentColor"
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="none"
                        font-size="none"
                        text-anchor="none"
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M47.16,21.221l-5.91,-0.966c-0.346,-1.186 -0.819,-2.326 -1.411,-3.405l3.45,-4.917c0.279,-0.397 0.231,-0.938 -0.112,-1.282l-3.889,-3.887c-0.347,-0.346 -0.893,-0.391 -1.291,-0.104l-4.843,3.481c-1.089,-0.602 -2.239,-1.08 -3.432,-1.427l-1.031,-5.886c-0.084,-0.478 -0.499,-0.828 -0.985,-0.828h-5.5c-0.49,0 -0.908,0.355 -0.987,0.839l-0.956,5.854c-1.2,0.345 -2.352,0.818 -3.437,1.412l-4.83,-3.45c-0.399,-0.285 -0.942,-0.239 -1.289,0.106l-3.887,3.887c-0.343,0.343 -0.391,0.883 -0.112,1.28l3.399,4.863c-0.605,1.095 -1.087,2.254 -1.438,3.46l-5.831,0.971c-0.482,0.08 -0.836,0.498 -0.836,0.986v5.5c0,0.485 0.348,0.9 0.825,0.985l5.831,1.034c0.349,1.203 0.831,2.362 1.438,3.46l-3.441,4.813c-0.284,0.397 -0.239,0.942 0.106,1.289l3.888,3.891c0.343,0.343 0.884,0.391 1.281,0.112l4.87,-3.411c1.093,0.601 2.248,1.078 3.445,1.424l0.976,5.861c0.079,0.481 0.496,0.834 0.985,0.834h5.5c0.485,0 0.9,-0.348 0.984,-0.825l1.045,-5.89c1.199,-0.353 2.348,-0.833 3.43,-1.435l4.905,3.441c0.398,0.281 0.938,0.232 1.282,-0.111l3.888,-3.891c0.346,-0.347 0.391,-0.894 0.104,-1.292l-3.498,-4.857c0.593,-1.08 1.064,-2.222 1.407,-3.408l5.918,-1.039c0.479,-0.084 0.827,-0.5 0.827,-0.985v-5.5c0.001,-0.49 -0.354,-0.908 -0.838,-0.987zM25,32c-3.866,0 -7,-3.134 -7,-7c0,-3.866 3.134,-7 7,-7c3.866,0 7,3.134 7,7c0,3.866 -3.134,7 -7,7z"></path>
                        </g>
                      </g>
                    </svg>
                    Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    onClick={() => dispatch(logout())}
                    style={{ gap: "10px", padding: "8px 0px", display: "flex" }}
                  >
                    {/* <svg
                      fill="#1814f3"
                      height="20px"
                      width="20px"
                      version="1.1"
                      id="Capa_1"
                      viewBox="0 0 471.2 471.2"
                      stroke="#1814f3"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5 s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5 S235.019,444.2,227.619,444.2z" />{" "}
                            <path d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8 C455.319,239.9,455.319,231.3,450.019,226.1z" />{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg> */}
                    <svg className="fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 10L11 6V9H18V11H11V14L6 10Z" fill="currentColor"/>
<path d="M4 2L4 18H11V20H4C2.897 20 2 19.103 2 18L2 2C2 0.896999 2.897 0 4 0H11V2H4Z" fill="currentColor"/>
</svg>
                    Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="ThemeButton">
          <div onClick={handleThemeToggle} className="headericons">
            <img
              style={{ width: "15px" }}
              src={theme === "dark" ? sunlight : moonlight}
              alt="Theme Toggle"
            />
          </div>
        </div>
      </div>
      
      
     
      </div>
      </div>
      {
        JobDetailsData !== null ? 
        <div className="mobiledetailsheader" style={{display:"flex",alignItems:"center"}}> <div className="JobDetailsHeader">
        <div className="Sec">
          Job #J{JobDetailsData.job_id}
        </div>
        <div className="Sec">
          Site: {JobDetailsData.site_name}
        </div>
        <div className="Sec">
        Client: {JobDetailsData.client_firm}
        </div>
        <div className="Sec">
        Project Manager: {JobDetailsData.manager}
        </div>
      </div></div> : <div ></div>
      }
    </header>
  );
};

export default Header;
