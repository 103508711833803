import { configureStore } from "@reduxjs/toolkit";
import AllApis from "./AllApis";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import Auth from "./Auth";


const persistConfig = {
    key: 'root',
    storage, 
    whitelist: ['theme'], 
  };

  const persistedReducer = persistReducer(persistConfig, AllApis);

const store = configureStore({
    reducer: {
        allapi: persistedReducer,
        auth: Auth
    }
})

export const persistor = persistStore(store);
export default store;