import styled from "@emotion/styled";
import { Box, CircularProgress, LinearProgress, linearProgressClasses, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import progressicon from "../assets/images/progressicon.svg";


export const DateForm = ({ datenew }) => {
    const date = new Date(datenew);

    const options = { day: 'numeric', month: 'short', year: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
};


export const formatMonth1 = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-US', options);
};
export const formatMonth = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-US', options);
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#DADADA",
        marginTop: "3px",
        marginBottom: "10px",
        width: "230px",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#FF4359',

    },
}));


export const TotalLabCOProgress = (data) => {
    return <BorderLinearProgress variant="determinate" value={data} />
}


export const TotalLabCOCircular = (data) => {
    return <CircularProgress
        width="45px"
        height="45px"
        variant="determinate"
        value={data}
        thickness={3}
        sx={{
            transform: 'rotate(90deg) !important',
        }}>
        {/* <div className="chargeoutcircle">
            <img src={progressicon} width="15px" />
        </div> */}
    </CircularProgress>
}

export const LabourCircular = (data, img, color, Class) => {
    return (
        <div className={Class}>
            <CircularProgress size="md" determinate value={data} thickness={3} sx={{ stroke: color }}>
                <div className="iconcircle">
                    <img src={img} />
                </div>
            </CircularProgress>
        </div>
    )

}





// export const Filter = ({ name, options, isDateFilter = false, value, onChange }) => {
//     const [selectedOptions, setSelectedOptions] = useState(["All"]);
//     const [customDate, setCustomDate] = useState("");
//     const [isOpen, setIsOpen] = useState(false);
//     const filterRef = useRef(null);

//     useEffect(() => {
//         if (isDateFilter) {
//             onChange(customDate);
//         } else {
//             onChange(selectedOptions);
//         }
//     }, [customDate, selectedOptions, isDateFilter, onChange]);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (filterRef.current && !filterRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, []);

//     const handleSelect = (option) => {
//         setSelectedOptions((prevOptions) => {
//             if (option === "All") {
//                 return prevOptions.includes("All") ? [] : [...options];
//             } else {
//                 const newOptions = prevOptions.includes(option)
//                     ? prevOptions.filter((o) => o !== option)
//                     : [...prevOptions, option];

//                 if (newOptions.includes("All") && newOptions.length < options.length) {
//                     return newOptions.filter((o) => o !== "All");
//                 }

//                 if (newOptions.length === options.length - 1 && !newOptions.includes("All")) {
//                     newOptions.push("All");
//                 }

//                 return newOptions;
//             }
//         });
//     };

//     const handleDateChange = (e) => {
//         setCustomDate(e.target.value);
//     };

//     const togglePopup = () => {
//         setIsOpen(!isOpen);
//     };

//     return (
//         <div className="Filters" ref={filterRef}>
//             <div className="Item" onClick={togglePopup}>
//                 <div className="items">
//                     <div className="Name">{name} :</div>
//                     <div className="FilterItems">
//                         {isDateFilter && customDate
//                             ? customDate
//                             : selectedOptions.length === options.length
//                                 ? "All"
//                                 : selectedOptions.join(", ").length > 10
//                                     ? `${selectedOptions.join(", ").slice(0, 10)}...`
//                                     : selectedOptions.join(", ")}
//                     </div>
//                 </div>


//                 <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z" fill="#0035F0" />
//                 </svg>


//             </div>

//             {isOpen && (
//                 <div className="FilterOptions">
//                     {isDateFilter ? (
//                         <input
//                             type="date"
//                             value={customDate}
//                             onChange={handleDateChange}
//                             className="DateInput"
//                         />
//                     ) : (
//                         options.map((option) => (
//                             <div className="Options" key={option} onClick={() => handleSelect(option)}>
//                                 <div
//                                     className={`checkbox ${selectedOptions.includes(option) ? "Active" : ""}`}
//                                 ></div>
//                                 <div className="Option">{option}</div>
//                             </div>
//                         ))
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// };

export const formatText = (text) => {
    const formatted = text
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    return formatted.length > 10 ? `${formatted.substring(0, 10)}...` : formatted;
};

export const Filter = ({
    name,
    options = [],
    isDateFilter = false,
    value,
    onChange,
    classname,
    handleOpenDateRange,
    isTags = false,
    handleAddTags
}) => {
    const [selectedOption, setSelectedOption] = useState("All");
    const [customDate, setCustomDate] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const filterRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isDateFilter) {
            onChange(customDate);
        } else {
            onChange(selectedOption === "All" ? "" : selectedOption);
        }
    }, [customDate, selectedOption, isDateFilter, onChange]);

    const handleSelect = (option) => {
        if (isTags) {
            const updatedTags = selectedTags.includes(option.tag)
                ? selectedTags.filter((tag) => tag !== option.tag)
                : [...selectedTags, option.tag];
            setSelectedTags(updatedTags);
        } else {
            setSelectedOption(option);
            setIsOpen(false);
        }
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleGoClick = () => {
        if (isTags) {
            onChange(selectedTags);
            setIsOpen(false);
        }
    };

    const handleInputKeyPress = (event) => {
        if (event.key === "Enter") {
            handleAddTags(event.target.value);

        }
    };
    const colors = ['#F0E9FF', '#60EF88', '#B0A781', '#F9E0E0'];

    return (
        <div className={`Filters ${classname}`} ref={filterRef}>
            <div className="Item" onClick={isDateFilter ? handleOpenDateRange : togglePopup}>
                <div className="items">
                    <div className="Name">{name} :</div>
                    <div className="FilterItems">
                    {isDateFilter
  ? formatText(value || "All")
  : isTags
    ? (selectedTags.join(", ").length > 10 
        ? `${selectedTags.join(", ").substring(0, 10)}...` 
        : selectedTags.join(", ") || "All")
    : selectedOption.length > 10
      ? `${selectedOption.substring(0, 10)}...`
      : selectedOption}

                    </div>
                </div>

                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z" fill="#0035F0" />
                </svg>
            </div>

            {isOpen && !isDateFilter && !isTags ? (
                <div className="FilterOptions">
                    {[...options].map((option) => (
                        <div
                            className={`Options ${selectedOption === option ? "Active" : ""}`}
                            key={option}
                            onClick={() => handleSelect(option)}
                        >
                            <div className="Option">{option}</div>
                        </div>
                    ))}
                </div>
            ) : isOpen && !isDateFilter && isTags ? (
                <div className="FilterOptions">
                    <div className="TagOptions">
                        {[ ...options].map((option, i) => (
                            <div
                                className={`Options ${selectedTags.includes(option.tag) ? "Active" : ""}`}
                                key={option.id}
                                onClick={() => handleSelect(option)}
                                style={{
                                    backgroundColor: colors[i % colors.length]                                    
                                  }}
                            >
                                
                                <div className={`checkbox ${selectedTags.includes(option.tag) && "Active"}`}></div>
                                <div className="Option">{option.tag}</div>
                            </div>
                        ))}
                    </div>

                    <div onClick={handleGoClick} className="GoButton">
                        Filter &rarr;
                    </div>

                    <div className="Options">
                        <input
                            type="text"
                            placeholder="Create new..."
                            onKeyPress={handleInputKeyPress}
                        />
                    </div>
                </div>
            ) : ""}
        </div>
    );
};



// export const Filter = ({ name, options = [], isDateFilter = false, value, onChange, classname, handleOpenDateRange, isTags = false }) => {
//     const [selectedOption, setSelectedOption] = useState("All");
//     const [customDate, setCustomDate] = useState("All");
//     const [isOpen, setIsOpen] = useState(false);
//     const filterRef = useRef(null);

//     useEffect(() => {
//         if (isDateFilter) {
//             onChange(customDate);
//         } else {
//             onChange(selectedOption === "All" ? "" : selectedOption);
//         }
//     }, [customDate, selectedOption, isDateFilter, onChange]);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (filterRef.current && !filterRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, []);

//     const handleSelect = (option) => {
//         setSelectedOption(option);
//         setIsOpen(false);
//     };

//     const togglePopup = () => {
//         setIsOpen(!isOpen);
//     };

//     return (
//         <div className={`Filters ${classname}`} ref={filterRef}>
//             <div className="Item" onClick={isDateFilter ? handleOpenDateRange : togglePopup}>
//                 <div className="items">
//                     <div className="Name">{name} :</div>
//                     <div className="FilterItems">
//                         {isDateFilter
//                             ? formatText(value || "All")
//                             : selectedOption.length > 10
//                                 ? `${selectedOption.substring(0, 10)}...`
//                                 : selectedOption}
//                     </div>
//                 </div>

//                 <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z" fill="#0035F0" />
//                 </svg>
//             </div>

//             {isOpen && !isDateFilter && !isTags ? (
//                 <div className="FilterOptions">
//                     {["", ...options].map((option) => (
//                         <div className={`Options ${selectedOption === option ? "Active" : ""}`} key={option} onClick={() => handleSelect(option)}>
//                             <div className="Option">{option}</div>
//                         </div>
//                     ))}
//                 </div>
//             ) : isOpen && isTags ?
//                 (
//                     <div className="FilterOptions">
//                         {["", ...options].map((option) => (
//                             <div className={`Options ${selectedOption === option ? "Active" : ""}`} key={option.tag} onClick={() => handleSelect(option.tag)}>
//                                 <div className="Option">{option.tag}</div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : ""
//             }
//         </div>
//     );
// };










export const CompBar = ({ data }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
                className="CompBarBox"
            >
                {data}%
            </Box>
            <CircularProgress size={36} variant="determinate" value={data} thickness={4} className="CompBarCircle"
                sx={{
                    color: '#0035F0',
                    strokeLinecap: 'round',
                }}
            />

        </Box>
    );
}

export const StatFormat = ({ stat }) => {


    const statdata = stat.toLowerCase()

    const color = statdata === "tbc" ? "tbc" :
        statdata === "wip*" ? "wipNot" :
            statdata === "wip" ? "wip" :
                statdata === "done" ? "done" :
                    ""


    return (
        <div className={`StatTab ${color}`}>{stat}</div>
    )
}


export const formatDateRange = (range) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const dayOfWeek = today.getDay();

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const quarterStartMonths = [0, 3, 6, 9];
    const startOfQuarter = new Date(year, quarterStartMonths[Math.floor(month / 3)], 1);

    const startOfThisWeek = new Date(today);
    startOfThisWeek.setDate(day - dayOfWeek + 1);
    const endOfThisWeek = new Date(startOfThisWeek);
    endOfThisWeek.setDate(startOfThisWeek.getDate() + 6);

    const startOfLastWeek = new Date(startOfThisWeek);
    startOfLastWeek.setDate(startOfThisWeek.getDate() - 7);
    const endOfLastWeek = new Date(startOfThisWeek);
    endOfLastWeek.setDate(startOfThisWeek.getDate() - 1);

    const dateRanges = {
        "this_month": () => `${monthNames[month]} ${year}`,

        "last_month": () => {
            const lastMonth = new Date(year, month - 1, 1);
            return `${monthNames[lastMonth.getMonth()]} ${lastMonth.getFullYear()}`;
        },

        "this_quarter": () => {
            const endOfQuarter = new Date(year, startOfQuarter.getMonth() + 3, 0);
            return `1 ${monthNames[startOfQuarter.getMonth()]} - ${endOfQuarter.getDate()} ${monthNames[endOfQuarter.getMonth()]} ${year}`;
        },

        "last_quarter": () => {
            const lastQuarterStart = new Date(year, startOfQuarter.getMonth() - 3, 1);
            const lastQuarterEnd = new Date(year, startOfQuarter.getMonth(), 0);
            return `1 ${monthNames[lastQuarterStart.getMonth()]} - ${lastQuarterEnd.getDate()} ${monthNames[lastQuarterEnd.getMonth()]} ${lastQuarterStart.getFullYear()}`;
        },

        "this_financial_year": () => {
            const startOfFinancialYear = new Date(month >= 6 ? year : year - 1, 6, 1);
            const endOfFinancialYear = new Date(startOfFinancialYear.getFullYear() + 1, 5, 30);
            return `1 ${monthNames[startOfFinancialYear.getMonth()]} ${startOfFinancialYear.getFullYear()} - 30 ${monthNames[endOfFinancialYear.getMonth()]} ${endOfFinancialYear.getFullYear()}`;
        },

        "last_financial_year": () => {
            const startOfLastFinancialYear = new Date(year - 1, 6, 1);
            const endOfLastFinancialYear = new Date(year, 5, 30);
            return `1 ${monthNames[startOfLastFinancialYear.getMonth()]} ${startOfLastFinancialYear.getFullYear()} - 30 ${monthNames[endOfLastFinancialYear.getMonth()]} ${endOfLastFinancialYear.getFullYear()}`;
        },

        "month_to_date": () => `1 ${monthNames[month]} - ${day} ${monthNames[month]} ${year}`,

        "year_to_date": () => `1 Jan - ${day} ${monthNames[month]} ${year}`,

        "quarter_to_date": () => `1 ${monthNames[startOfQuarter.getMonth()]} - ${day} ${monthNames[month]} ${year}`,

        "this_week": () => `${startOfThisWeek.getDate()} ${monthNames[startOfThisWeek.getMonth()]} - ${endOfThisWeek.getDate()} ${monthNames[endOfThisWeek.getMonth()]} ${year}`,

        "last_week": () => `${startOfLastWeek.getDate()} ${monthNames[startOfLastWeek.getMonth()]} - ${endOfLastWeek.getDate()} ${monthNames[endOfLastWeek.getMonth()]} ${year}`
    };

    return dateRanges[range] ? dateRanges[range]() : "";
}


