import React, { useEffect } from "react";
import { Box, CircularProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Profile from "../components/Settings/Profile";
import ResetPass from "../components/Settings/ResetPass";
import { useDispatch } from "react-redux";
import { GetProfile } from "../reducers/AllApis";

const Settings = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetProfile());
  }, []);

  return (
    <div className="Settings CardOne">
      <div className="KPITabs">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                gap="10px"
              >
                <Tab
                  label="Edit Profile"
                  value="1"
                  sx={{ textTransform: "capitalize", mr: 3 }}
                />
                <Tab
                  label="Reset Password"
                  value="2"
                  sx={{ textTransform: "capitalize", mr: 3 }}
                />
               
              </TabList>
            </Box>
            <TabPanel value="1">
                <Profile/>
            </TabPanel>
            <TabPanel value="2">
              <ResetPass/>
     
            </TabPanel>
      
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default Settings;
