import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Jobs from './pages/Jobs';
import routes from './components/routes';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from './reducers/AllApis';
import DefaultLayout from './components/DefaultLayout';
import "./Main.scss";
import SignIn from './pages/Authentication/SignIn';
import Test from './Test';

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { theme } = useSelector((state) => state.allapi);


  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <div className={`App ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
    
      <BrowserRouter>
     
        <Routes>
        <Route
          path="/" 
          element={<SignIn />} 
        />
        
          {routes.map((x, i) => {
            return (
              <Route
                key={i}
                path={x.path}
                element={
                  <DefaultLayout PageHead={x.title}>
                    <x.component />
                  </DefaultLayout>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
