import numeral from "numeral";
import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import detailsfilter from "../../assets/images/details filter logo.svg";
import { formatMonth1 } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { SelectTimeFilter } from "../../reducers/AllApis";

const ProjectCost = ({
  ActualLabourRevArr,
  ActualLabourCostArr,
  Time_Sheet,
  EarnedValueArr,
}) => {
  const TimeSheet = Time_Sheet.map((x) => formatMonth1(x.period));
  const ActualLabourRevenue = ActualLabourRevArr.map((x) => x.toFixed(0));
  // const ActualLabourRevenue = ActualLabourRevArr.map((x) =>
  //   x ? Math.abs(x).toFixed(0) : "0"
  // );
  const ActualLabourCost = ActualLabourCostArr.map((x) => x.toFixed(0));
  // const ActualLabourCost = ActualLabourCostArr.map((x) =>
  //   x ? Math.abs(x).toFixed(0) : "0"
  // );
  
  const EarnedValue = EarnedValueArr.map((x) =>
    x ? Math.abs(x).toFixed(0) : "0"
  );

  const maxLength = Math.max(
    TimeSheet.length,
    ActualLabourRevenue.length,
    ActualLabourCost.length,

    EarnedValue.length
  );

  // const padArray1 = (arr, length, fillValue = null) => {
  //   return [...arr, ...Array(length - arr.length).fill(fillValue)];
  // };

  const padArray = (arr, length, fillValue = 0) => {
    return [
      ...arr.map((item) => Number(item)),
      ...Array(length - arr.length).fill(Number(fillValue)),
    ];
  };

  const paddedActualLabourRevenue = padArray(
    ActualLabourRevenue,
    maxLength,
    "0"
  );
  const paddedActualLabourCost = padArray(ActualLabourCost, maxLength, "0");
  const paddedEarnedValue = padArray(EarnedValue, maxLength, "0");

  const options = {
    title: {
      text: "Project Cost by Resources",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 25,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "800",
        fontFamily: "Inter, sans-serif",
        color: "var(--Table-Field-Names)",
      },
    },
    legend: {
      offsetY: -10,
      position: "top",
      horizontalAlign: "right",
      fontSize: "12px",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      letterSpacing: "10px",
      marginTop: "-10px",
      // marginLeft:"-40px",
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1400,
        options: {
          legend: {
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
          },
        },
      },
      {
        breakpoint: 1350,
        options: {
          title: {
            offsetY: -5,

          },
        },
      },
    ],
    colors: ["#36D462", "#FFB800", "#FF4359"],
    chart: {
      height: 350,
      width: 600,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "category",
      categories: TimeSheet,
      labels: {
        rotate: -90,
        style: {
          colors: "#959595",
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          fontWeight: 500,
          marginTop: "5px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#959595",
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          fontWeight: 500,
        },
        formatter: (value) => numeral(value).format("$0,0"),
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
    },
  };

  const series = [
    {
      name: "Labour Revenue",
      data: paddedActualLabourRevenue,
    },
    {
      name: "Actual Cost",
      data: paddedActualLabourCost,
    },
    {
      name: "Earned Value",
      data: paddedEarnedValue,
    },
  ];

  const detailspopupRef = useRef(null);
  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const detailstogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      detailspopupRef.current &&
      !detailspopupRef.current.contains(event.target)
    ) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch()
  
  const {SelectedTime} = useSelector((state) => state.allapi)
  const handleSelectTime = (data) => {
     dispatch(SelectTimeFilter(data))
  }


  return (
    <div id="chart" className="ProjectCostChart">
      <div
        className="detailsfilter"
        onClick={detailstogglePopup}
        ref={inputRef}
      >
        {/* <img src={detailsfilter}/> */}
 
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2212 18V16H14.2212V18H10.2212ZM6.22119 13V11H18.2212V13H6.22119ZM3.22119 8V6H21.2212V8H3.22119Z"
            fill="currentColor"
          />
        </svg>
        <p>{SelectedTime === "monthly" ?  "Monthly" : SelectedTime === "yearly" ? "Yearly" : SelectedTime === "quarterly" ? "Quarterly" : "View by"}</p>

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.5427 8.95001L14.0227 15.47C13.2527 16.24 11.9927 16.24 11.2227 15.47L4.70267 8.95001" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        {isPopupOpen && (
          <div className="graphpopup" ref={detailspopupRef}>
            <div className="popup-content">
            <p onClick={(e) => handleSelectTime("monthly")}>Monthly</p>
              <p onClick={(e) => handleSelectTime("yearly")}>Yearly</p>
              <p onClick={(e) => handleSelectTime("quarterly")}>Quarterly</p>
            </div>
          </div>
        )}
      </div>

      <Chart
        options={options}
        series={series}
        type="area"
        height={350}
        width="100%"
      />
    </div>
  );
};

export default ProjectCost;
