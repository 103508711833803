import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ActiveJobPopup,
  ActivePopup,
  AddTags,
  InactiveJobPopup,
  InactivePopup,
  ListExcel,
  ListExcelSearch,
  ProjectManagers,
  ResetSuccessUpdate,
  ResetTable,
  Tags,
} from "../reducers/AllApis";
import {
  CompBar,
  DateForm,
  Filter,
  formatMonth,
  StatFormat,
} from "../utils/utils";
import { Skeleton, Stack } from "@mui/material";
import AddJobs from "../components/Jobs/AddJobs";
import numeral from "numeral";
import axios from "axios";
import DateRangeFilter from "../components/Jobs/DateRangeFilter";
import Manager from "../assets/images/ManagerPlaceholder.svg";

const Jobs1 = () => {
  const [status, setStatus] = useState("");
  const [dateType1, setDateType1] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [TagsFilterData, setTagsFilterData] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [itemsToShow, setItemsToShow] = useState(10);
  const itemsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);

  const observer = useRef();

  const updateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      setItemsToShow(8);
    } else if (screenWidth > 601 && screenWidth < 1700) {
      setItemsToShow(10);
    } else if (screenWidth > 1700) {
      setItemsToShow(20);
    }
  };

  useEffect(() => {
    updateItemsPerPage();

    window.addEventListener("resize", updateItemsPerPage);

    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  useEffect(() => {
    dispatch(ResetTable());
  }, [dispatch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(ListExcelSearch(searchTerm));
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch]);

  const handleNav = (data) => {
    navigate(`/jobs/${data.job_id}`, {
      state: { data: data.job_id },
    });
  };

  const {
    Listdata,
    OpenPopup,
    ProjectManagersData,
    TagsData,
    OpenJobPopup,
    AddTagsSuccess,
  } = useSelector((state) => state.allapi);

  const currentItems = Listdata ? Listdata.slice(0, itemsToShow) : [];

  const loadMoreItems = () => {
    setIsLoading(true);
    setTimeout(() => {
      setItemsToShow((prev) => prev + itemsPerPage);
      setIsLoading(false);
    }, 1000);
  };

  const lastItemRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            itemsToShow < (Listdata ? Listdata.length : 0)
          ) {
            loadMoreItems();
          }
        },
        {
          rootMargin: "-15% 0px",
        }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, itemsToShow, Listdata]
  );

  const JobPopupRef = useRef();

  const handleDatePopup = () => {
    dispatch(ActivePopup());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (JobPopupRef.current && !JobPopupRef.current.contains(event.target)) {
        dispatch(InactivePopup());
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleClearFil = () => {
    setDateType1("");
    setStartDate("");
    setEndDate("");
  };

  // useEffect(() => {

  //   const formData = new FormData();

  //   TagsFilterData && TagsFilterData.forEach(tag => {
  //     formData.append('tag', tag);
  //   });

  //   const data = {
  //     category,
  //     status,
  //     filter_type: dateType1,
  //     custom_start_date: StartDate,
  //     custom_end_date: EndDate,
  //   };

  //   Object.entries(data).forEach(([key, value]) => {
  //     if (value) {  // Only append fields that have a value
  //       formData.append(key, value);
  //     }
  //   });
  //   for (let [key, value] of formData.entries()) {
  //     console.log(`${key}: ${value}`);
  //   }

  //   if ((!StartDate && !EndDate) || (StartDate && EndDate)) {
  //     dispatch(ListExcel(data));
  //   }
  //   // dispatch(ListExcel(data));
  // }, [status, category, dateType1, StartDate, EndDate, TagsFilterData]);

  useEffect(() => {
    const params = new URLSearchParams();

    TagsFilterData &&
      TagsFilterData.forEach((tag) => {
        params.append("tags", tag);
      });

    const data = {
      project_manager_name: category,
      status,
      filter_type: dateType1,
      custom_start_date: StartDate,
      custom_end_date: EndDate,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });

    if ((!StartDate && !EndDate) || (StartDate && EndDate)) {
      dispatch(ListExcel(params));
    }
  }, [
    status,
    category,
    dateType1,
    StartDate,
    EndDate,
    TagsFilterData,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(ProjectManagers());
    dispatch(Tags(""));
  }, []);

  const handleSelectData = (data) => {
    setDateType1(data);
  };
  const handleSelectDataCustom = (data, type) => {
    if (type === "start") {
      setStartDate(data);
    } else if (type === "end") {
      setEndDate(data);
    }
  };

  const JobDataPopupRef = useRef();

  const handleJobPopup = () => {
    dispatch(ActiveJobPopup());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        JobDataPopupRef.current &&
        !JobDataPopupRef.current.contains(event.target)
      ) {
        dispatch(InactiveJobPopup());
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleAddTags = (data) => {
    const obj = {
      tag:data
    }
    dispatch(AddTags(obj));
  };

  useEffect(() => {
    if (AddTagsSuccess === true) {
      dispatch(Tags(""));
      dispatch(ResetSuccessUpdate())
      
    }
  }, [AddTagsSuccess]);

  return (
    <div className="NewJob">
      <div className="FilterTable">
        <div className="FilterItemDiv">
          <Filter
            name="Status"
            options={["TBC", "WIP*", "WIP", "DONE"]}
            value={status}
            onChange={setStatus}
          />
          <Filter
            name="Date Range"
            options={["Custom"]}
            isDateFilter={true}
            value={dateType1}
            onChange={setDateType1}
            handleOpenDateRange={handleDatePopup}
          />

          <Filter
            name="Tag Selector"
            options={TagsData}
            value={TagsFilterData}
            onChange={setTagsFilterData}
            isTags={true}
            handleAddTags={handleAddTags}
          />

          <Filter
            name="Project Manager"
            options={ProjectManagersData}
            value={category}
            onChange={setCategory}
            classname="ProjectManager"
          />
          <input
            className="SearchJobs"
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={(e) => {
              setCategory("");
              setSearchTerm(e.target.value);
            }}
            style={{}}
          />
        </div>

        {/* <button onClick={handleApiCall}>Apply Filters</button> */}

        {/* <div className="AddJobs" onClick={handleJobPopup}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3335 8.66665H3.3335V7.33331H7.3335V3.33331H8.66683V7.33331H12.6668V8.66665H8.66683V12.6666H7.3335V8.66665Z"
              fill="#0035F0"
            />
          </svg>

          <div>Create Job</div>
        </div>
        {OpenJobPopup && (
          <div className="AddJobForm">
            <AddJobs
              Data={dateType1}
              Start_Date={StartDate}
              End_Date={EndDate}
              ref={JobDataPopupRef}
              handleSelectData={handleSelectData}
              handleSelectDataCustom={handleSelectDataCustom}
              handleClearFil={handleClearFil}
            />
          </div>
        )} */}
        {OpenPopup && (
          <div className="AddJobForm">
            <DateRangeFilter
              Data={dateType1}
              Start_Date={StartDate}
              End_Date={EndDate}
              ref={JobPopupRef}
              handleSelectData={handleSelectData}
              handleSelectDataCustom={handleSelectDataCustom}
              handleClearFil={handleClearFil}
            />
          </div>
        )}
      </div>

      <div className="JobTable">
        <table>
          <thead>
            <tr>
              <th>Project manager</th>
              <th>Job no.</th>
              <th>Start</th>
              <th>End</th>
              <th>Status</th>
              <th>Revenue</th>
              <th>WIP</th>
              <th>Site Name</th>
              <th>% Comp</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((x, i) => {
                // let formattedStatus =
                //   x.status &&
                //   x.status.charAt(0) + x.status.slice(1).toLowerCase();
                return (
                  <tr
                    key={i}
                    onClick={() => handleNav(x)}
                    ref={i === currentItems.length - 1 ? lastItemRef : null}
                  >
                    <td>
                      <img src={Manager} />
                      {x.project_manager ? x.project_manager : "-"}
                    </td>
                    <td>J{x.job_id ? x.job_id : "-"}</td>
                    <td>{x.start_date ? formatMonth(x.start_date) : "-"}</td>
                    <td>{x.end_date ? formatMonth(x.end_date) : "-"}</td>
                    <td>
                      {x.status ? (
                        <StatFormat stat={x.status.toUpperCase()} />
                      ) : (
                        "-"
                      )}
                      {/* <div
                        className={
                          x.status === "ACTIVE"
                            ? "ACTIVEtab"
                            : x.status === "INACTIVEtab"
                            ? "INACTIVE"
                            : ""
                        }
                      >
                        {formattedStatus ? formattedStatus : "-"}
                      </div> */}
                    </td>
                    <td>
                      {x.revenue ? numeral(x.revenue).format("$0,0") : "-"}
                    </td>
                    <td>{x.wip ? numeral(x.wip).format("$0,0") : "-"}</td>
                    <td>{x.site ? x.site : "-"}</td>

                    <td>
                      {x.percent_complete ? (
                        <CompBar data={x.percent_complete} />
                      ) : (
                        "-"
                      )}
                    </td>
                    {/*                  
                  <td>
                    <div className="ViewButton" >View</div>
                  </td> */}
                  </tr>
                );
              })
            ) : (
              <div className="ItemHead">No Data</div>
            )}
          </tbody>
        </table>
        <div className="SkeletonContainer">
          {isLoading || itemsToShow < (Listdata ? Listdata.length : 0) ? (
            <Stack spacing={1} className="SkeletonLoader">
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            </Stack>
          ) : null}
        </div>

        {/* 
        <div className="Header TableRow">
          <div className="Item W-120">Project Manager</div>
          <div className="Item W-80">Job no</div>
          <div className="Item W-100">Start Month</div>
          <div className="Item W-100">End Month</div>
          <div className="Item W-120">Sales Person</div>
          <div className="Item W-300">Site Name</div>
          <div className="Item W-100">Status</div>
          <div className="Item W-80"></div>
        </div>

        {Listdata && Listdata.map((x, i) => {
          let formattedStatus =
            x.status && x.status.charAt(0) + x.status.slice(1).toLowerCase();
          return (
            <div className="DataTable TableRow" key={x.job_id || i}>
              <div className="Item W-120">{x.manager ? x.manager : "-"}</div>
              <div className="Item W-80">J{x.job_id}</div>
              <div className="Item W-100">
                {x.start_date ? formatMonth(x.start_date) : "-"}
              </div>
              <div className="Item W-100">
                {x.end_date ? formatMonth(x.end_date) : "-"}
              </div>
              <div className="Item W-120">
                {x.sales_person ? x.sales_person : "-"}
              </div>
              <div className="Item W-300">
                {x.site_name ? x.site_name : "-"}
              </div>
              <div className="Item W-100">
                <div
                  className={
                    x.status === "ACTIVE"
                      ? "ACTIVEtab"
                      : x.status === "INACTIVE"
                      ? "INACTIVEtab"
                      : ""
                  }
                >
                  {formattedStatus}
                </div>
              </div>
              <div className="Item W-80">
                <div className="ViewButton">View</div>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default Jobs1;
